import { styled } from 'styletron-react';

import { getTheme, getLum } from '../../themes';


const profilePic = ({ $size = 0, $border = false }) => ({
    boxSizing: 'border-box',
    display: 'block',
    width: `${$size}px`,
    height: `${$size}px`,
    borderRadius: '50%',

    ...($border && {
        border: `2px solid ${getTheme().primaryText}`,
        boxShadow: '0 1px 0 rgba(0,0,0,0.1)',
    }),
});

export const UserPhoto = styled('img', props => ({
    ...profilePic(props),
}));

export const UserAvatar = styled('div', ({ $hash = '', $size = 0, $border = false }) => {
    const lum = getLum($hash);
    const treshold = 0.7;

    return {
        ...profilePic({ $size, $border }),
        background: `#${$hash.substring(0, 6)}`,
        color: lum > treshold ? '#000' : '#fff',
        textAlign: 'center',
        lineHeight: `${$size - ($border ? 4 : 0)}px`,
        fontSize: `${Math.floor($size / 3) + 2}px`,
        fontWeight: '600',
        textShadow: `0 1px 0 ${lum > treshold ? 'rgba(255, 255, 255, 0.75)' : 'rgba(0, 0, 0, 0.3)'}`,
    }
});

import React, { Component } from 'react';

import {
    UserAvatar,
    UserPhoto,
} from './Styles';

class UserPic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageError: false,
        };
    }

    render() {
        const {
            hash,
            size,
            name,
            border = false,
            ...props
        } = this.props;

        return (
            this.state.imageError
                ? (
                    <UserAvatar
                        {...props}
                        $hash={hash}
                        $size={size}
                        $border={border}
                    >
                        {name ? name.substring(0,1).toUpperCase() : null}
                    </UserAvatar>
                )
                : (
                    <UserPhoto
                        {...props}
                        src={`https://www.gravatar.com/avatar/${hash}?s=${size*2}&d=404`}
                        $hash={hash}
                        $size={size}
                        $border={border}
                        onError={() => this.setState({ imageError: true })}
                    />
                )
        );
    }
}

export default UserPic;

import React from 'react';

import {
    proFeatures,
} from '../features';

import {
    Tier,
    Title,
    Subtitle,
    TierFeatures,
    Price,
    PriceExtra,
    SmallPrint,
} from './PricingStyles';
import PricingFeature from './PricingFeature';

import { Button } from '../../../ui/components/input/ButtonStyles';
import { getPricing, getUnitPrice, getFrequency } from '../../../helpers/pricing';


const proPricing = getPricing('pro');

const PricingTierPro = ({
    pricing = 'year',
    userLevel = 0,
    canSelectPlans = true,
    selectLabel = 'select',
    onProSelect = () => {},
    showTrial = true,
}) => (
    <Tier $level={2} $pro>
        <Title $level={2}>Pro</Title>
        <Subtitle>More features. Private snippets.</Subtitle>
        <Price>€{getUnitPrice(proPricing, pricing)}</Price>
        <PriceExtra>
            per month
            <br />
            <SmallPrint>{getFrequency(proPricing, pricing)}</SmallPrint>
            <SmallPrint>{ showTrial ? '1 month free trial' : <>&nbsp;</> }</SmallPrint>
        </PriceExtra>

        { canSelectPlans && (
            <PriceExtra>
                <Button
                    $large
                    disabled={(userLevel === 2 && selectLabel !== 'resubscribe') || userLevel > 2}
                    onClick={onProSelect}
                >
                    { (userLevel === 2)
                        ? (selectLabel === 'resubscribe' ? 'Resubscribe' : 'Current plan')
                        : (selectLabel === 'upgrade' ? 'Upgrade' : 'Select plan')
                    }
                </Button>
            </PriceExtra>
        )}

        <TierFeatures>
            { proFeatures.map((f, i) => <PricingFeature key={i} feature={f} />) }
        </TierFeatures>
    </Tier>
);

export default PricingTierPro;

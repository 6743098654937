import React from 'react';

import {
    freeFeatures,
} from '../features';

import {
    Tier,
    Title,
    Subtitle,
    TierFeatures,
    Price,
    PriceExtra,
    SmallPrint,
} from './PricingStyles';
import PricingFeature from './PricingFeature';

import { Button } from '../../../ui/components/input/ButtonStyles';


const PricingTierBasic = ({
    userLevel = 0,
    canSelectPlans = true,
    onFreeSelect = () => {},
}) => (
    <Tier $level={1}>
        <Title $level={1}>Basic</Title>
        <Subtitle>For personal use and public sharing.</Subtitle>
        <Price>€0</Price>
        <PriceExtra>
            &nbsp;
            <br />
            <SmallPrint>Always free</SmallPrint>
            <SmallPrint>&nbsp;</SmallPrint>
        </PriceExtra>

        { canSelectPlans && (
            <PriceExtra>
                { userLevel === 1
                    ? <Button $large disabled>
                        Current plan
                    </Button>
                    : <Button $large disabled={userLevel > 1} onClick={onFreeSelect}>
                        Get started
                    </Button>
                }
            </PriceExtra>
        )}

        <TierFeatures>
            { freeFeatures.map((f, i) => <PricingFeature key={i} feature={f} />) }
        </TierFeatures>
    </Tier>
);

export default PricingTierBasic;

import React from 'react';

import {
    SideButtonWrapper,
    SideLink,
    SideLogo,
    BadgeWrapper,
    activeSideLink,
} from './SideStyles';
import Badge from '../../../ui/components/badge/Pro';


const SideButton = ({ to = '/', title = '-', Icon, pro_feature = false }) => (
    <SideButtonWrapper>
        <SideLink to={to} activeClassName={activeSideLink}>
            <SideLogo><Icon size="24px" /></SideLogo>
            {title}

            { pro_feature && (
                <BadgeWrapper>
                    <Badge $fill $sup $rSpaced />
                </BadgeWrapper>
            )}
        </SideLink>
    </SideButtonWrapper>
);

export default SideButton;

import React from 'react';

import {
    HeaderWrapper,
    Header,
    Logo,
    LogoSVG,
    TopNav,
    NavItem,
    NavItemLink,
} from './mainStyles';
// import { NavButton } from '../../ui/components/input/ButtonStyles';

export default () => (
    <HeaderWrapper>
        <Header>
            <Logo to="/">
                <LogoSVG size="32px" />
                Snipit
            </Logo>
            <TopNav>
                <NavItem href="/features">Features</NavItem>
                <NavItem href="/extensions">Extensions</NavItem>
                <NavItem href="/pricing">Pricing</NavItem>
                <NavItemLink to="/public">Public snippets</NavItemLink>
                {/*<NavItem href="/login">Sign In</NavItem>*/}
                {/*<NavButton to="/signup">Sign Up</NavButton>*/}
            </TopNav>
        </Header>
    </HeaderWrapper>
);

import { NavLink } from 'react-router-dom';
import { styled, withStyle } from 'styletron-react';

import { SnipitLogo } from '../../ui/icons';
import { getTheme } from '../../ui/themes';

const Styles = {
    wrapper: {
        boxSizing: 'border-box',
        maxWidth: '100%',
        width: '1040px',
        margin: '0 auto',
    },
    wideWrapper: {
        width: '1240px',
    },
    link: {
        color: getTheme().primary,
        textDecoration: 'none',
        ':hover': {
            color: getTheme().primaryDark,
            textDecoration: 'underline',
        },
    },
    invertedLink: {
        color: getTheme().primaryTextMuted,
        textDecoration: 'none',
        ':hover': {
            color: getTheme().primaryText,
            textDecoration: 'underline',
        },
    },
};


export const Wrapper = styled('div', Styles.wrapper);

export const FlexWrapper = withStyle(Wrapper, ({ $padded }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    zIndex: '1',
    ...($padded && { margin: '40px auto 0' }),
}));

export const WideWrapper = styled('div', {
    ...Styles.wrapper,
    ...Styles.wideWrapper,
});

export const HeaderWrapper = withStyle(WideWrapper, {
    borderBottom: `1px solid ${getTheme().primaryTextMuted}`,
});
export const Header = withStyle(Wrapper, {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 8px',
});

export const Footer = styled('div', {
    padding: '40px 0',
    background: getTheme().primaryDark,
    color: getTheme().primaryTextMuted,
    fontSize: '12px',

    '@media (max-width: 640px)': {
        marginTop: '100px',
        padding: '40px 20px',
    },
});

export const FooterLink = styled('a', Styles.invertedLink);
export const FooterNavLink = styled(NavLink, Styles.invertedLink);

export const Logo = styled(NavLink, {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: '300',
    color: getTheme().primary,
    fill: getTheme().primary,
    textDecoration: 'none',

    '@media (max-width: 640px)': {
        zoom: '1.5',
        margin: '0 auto',
    },
});

export const LogoFooter = withStyle(Logo, {
    fontSize: '16px',
    lineHeight: '24px',
    color: getTheme().primaryText,
    fill: getTheme().primaryText,
});

export const LogoSVG = styled(SnipitLogo, {
    verticalAlign: 'bottom',
    marginRight: '8px',
});

export const TopNav = styled('nav', {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '200',

    '@media (max-width: 640px)': {
        display: 'none',
    },
});

const navItemStyle = props => ({
    display: 'inline-block',
    padding: '10px 0',
    color: props.$color || getTheme().defaultTextMuted,
    textDecoration: 'none',
    ':hover': {
        color: getTheme().defaultTextLight,
    },
    ':not(:last-child)': {
        marginRight: '20px',
    },
});
export const NavItem = styled('a', navItemStyle);
export const NavItemLink = styled(NavLink, navItemStyle);

export const Main = styled('main', {
    ...Styles.wrapper,
    width: '720px',
    margin: '90px auto 140px',
    textAlign: 'center',

    '@media (max-width: 640px)': {
        margin: '40px auto 60px',
    },
});

export const BR = styled('br', {
    '@media (max-width: 640px)': {
        display: 'none',
    },
});

export const MainCaption = styled('h1', ({ $center }) => ({
    margin: '0 auto 60px',
    padding: '0 20px',
    fontSize: '24px',
    fontWeight: '200',
    color: getTheme().defaultTextLight,
    ...($center && { textAlign: 'center' }),
}));

export const MainCaptionBig = styled('span', {
    display: 'block',
    fontSize: '48px',
    fontWeight: '600',
    marginBottom: '24px',

    '@media (max-width: 640px)': {
        fontSize: '32px',
    },
});

export const MainAuth = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '16px',
    color: getTheme().defaultTextLight,
});
export const MainAuthText = styled('div', {
    margin: '0 16px',
});
export const MainAuthCaption = withStyle(MainAuthText, {
    flex: '0 0 100%',
    width: '100%',
    minWidth: '0',
    margin: '0 16px 16px',
});

export const Section = styled('section', props => ({
    ...(!props.$noMargin && {
        margin: '60px 0 0',
        '@media (min-width: 768px)': {
            margin: '100px 0 0',
        },
    }),
    ...(props.$padded
        ? {
            padding: '20px',
            '@media (min-width: 768px)': {
                padding: '60px 20px',
            }
        }
        : { padding: '0 20px' }
    ),
    ...(props.$hideOverflow && { overflow: 'hidden' }),
    ...(props.$primary && { backgroundColor: getTheme().primary, color: getTheme().primaryText }),
    ...(props.$dark && { backgroundColor: getTheme().primaryDark, color: getTheme().primaryTextMuted }),
    ...(props.$muted && { backgroundColor: getTheme().primaryTextMuted, color: getTheme().defaultTextLight }),
    ...(props.$relative && { position: 'relative' }),

    ...(props.$divider && {
        position: 'relative',
        '::after': {
            content: '""',
            display: 'block',
            width: '80%',
            position: 'absolute',
            bottom: '-30px',
            left: '10%',
            borderBottom: `1px solid ${getTheme().primaryTextMuted}`,
        }
    }),
}));

export const SectionTitle = styled('h1', props => ({
    marginBottom: '40px',
    marginTop: props.$spaced ? '40px' : '0',
    fontSize: props.$small ? '18px' : '22px',
    textTransform: 'uppercase',
    color: props.$dark ? getTheme().primaryTextMuted : getTheme().defaultTextMuted,
    ...(props.$center && { textAlign: 'center' }),
}));

export const SectionBlock = styled('div', props => ({
    flex: '1 1 1px',
    minWidth: '0',
    overflow: 'visible',
    lineHeight: '1.5',

    ':first-child': {
        marginRight: '40px',
    },

    ...(props.$center && {
        display: 'flex',
        alignItems: 'center',
    }),

    ...(props.$textRight && {
        '@media (min-width: 768px)': {
            textAlign: 'right',
        },
    }),

    '@media (max-width: 767px)': {
        flex: '0 0 100%',
        ...(props.$secondPosition && { order: '1' }),

        ':first-child': {
            marginRight: '0',
            marginBottom: '30px'
        },
    },
}));

export const SectionRow = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
});

export const RowLabel = styled('p', {
    color: getTheme().primaryTextLight,
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '800',

    marginRight: '20px',

    '@media (max-width: 567px)': {
        flex: '0 0 100%',
        marginRight: '0',
        marginBottom: '20px',
    },
});

export const MobileOnly = styled('div', {
    display: 'block',
    '@media (min-width: 768px)': {
        display: 'none',
    },
});
export const DesktopOnly = styled('div', {
    display: 'none',
    '@media (min-width: 768px)': {
        display: 'block',
    },
});

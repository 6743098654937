import React from 'react';
import { styled, withStyle } from 'styletron-react';

import config from '../../config/environments';
import {
    FlexWrapper,
    Section,
    SectionTitle,
    SectionBlock,
    SectionRow,
    RowLabel,
    DesktopOnly,
    MobileOnly,
} from './mainStyles';

import { LinkButtonA } from '../../ui/components/input/ButtonStyles';


const LinkHref = withStyle(LinkButtonA, {
    display: 'inline-block',
    textAlign: 'center',
});

const Image = styled('img', {
    display: 'block',
    marginBottom: '12px',

    '@media (max-width: 767px)': {
        width: '48px',
    },
});

const SideImage = styled('img', {
    filter: 'drop-shadow(0 0 10px rgba(0,0,0,0.5))',
});

export default () => (
    <Section id="browser-extension" $padded $hideOverflow>
        <FlexWrapper>
            <SectionBlock>
                <DesktopOnly>
                    <SectionTitle $spaced>Get the browser extension</SectionTitle>
                </DesktopOnly>
                <MobileOnly>
                    <SectionTitle>Get the browser extension</SectionTitle>
                </MobileOnly>
                <div>Snip code blocks directly from the pages you visit.
                    Just select some text, and the Snipit button is right there for you.</div>
                <p>If the page contains valid markup, Snipit even fills most of the fields for you: title, syntax and tags.
                    Click "Save" and you're done!</p>
                <p>&nbsp;</p>
                <SectionRow>
                    <RowLabel>Available for:</RowLabel>
                    <LinkHref target="_blank" href="https://chrome.google.com/webstore/detail/snipit/dodniekpimecafnhdbfaihfgdgbihhjl">
                        <Image src={`${config.application.cdnUrl}/images/icons/chrome.svg`} width="64" alt="chrome browser" />
                        Chrome
                    </LinkHref>

                    {/*https://safari-extensions.apple.com/details/?id=xyz.snipit.extensions.safari-3376Q2S9Z9*/}
                    <LinkHref target="_blank" href="/Snipit.safariextz">
                        <Image src={`${config.application.cdnUrl}/images/icons/safari.svg`} width="64" alt="safari browser" />
                        Safari
                    </LinkHref>

                    <LinkHref target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/snipit_io/">
                        <Image src={`${config.application.cdnUrl}/images/icons/firefox.svg`} width="64" alt="firefox browser" />
                        Firefox
                    </LinkHref>
                </SectionRow>
            </SectionBlock>
            <SectionBlock>
                <SideImage src={`${config.application.cdnUrl}/images/extension.png`} width="960" alt="screen shot of the Snipit.io browser extension" />
            </SectionBlock>
        </FlexWrapper>
    </Section>
);

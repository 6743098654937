import React from 'react';

import config from '../../config/environments';
import { TrackEvent } from '../../helpers/tracker';

import { MainAuthText } from './mainStyles';
import { LoginLink, GoogleIcon, GitHubIcon } from '../../ui/components/input/ButtonStyles';

export default () => (
    <>
        <LoginLink
            href={`${config.api.authUrl}/google`}
            title="Sign in with Google"
            rel="nofollow"
            onClick={() => TrackEvent('home', 'login', 'Google')}
        >
            <GoogleIcon />
            Google
        </LoginLink>
        <MainAuthText>or</MainAuthText>
        <LoginLink
            href={`${config.api.authUrl}/github`}
            title="Sign in with GitHub"
            rel="nofollow"
            onClick={() => TrackEvent('home', 'login', 'Github')}
        >
            <GitHubIcon />
            GitHub
        </LoginLink>
    </>
);

const getVHAlignment = (position, align) => {
    switch (position) {
        case 'left':
        case 'right':
            return `vertical_${align}`;
        case 'top':
        case 'bottom':
            return `horizontal_${align}`;
        default:
            return align;
    }
};

export const getAlignment = (position, align) => {
    const alignment = getVHAlignment(position, align);

    switch (alignment) {
        case 'horizontal_start':
            return 'left';
        case 'horizontal_center':
        case 'horizontal_middle':
            return 'center';
        case 'horizontal_end':
            return 'right';
        case 'vertical_start':
            return 'top';
        case 'vertical_center':
        case 'vertical_middle':
            return 'middle';
        case 'vertical_end':
            return 'bottom';
        default:
            return 'left';
    }
};

export const getPosition = (position) => {
    if (['top', 'right', 'bottom', 'left'].indexOf(position) !== -1) {
        return position;
    }

    return 'bottom';
};

export const getTrianglePosition = (popoverPosition) => {
    const position = getPosition(popoverPosition);

    switch (position) {
        case 'bottom':
            return 'top';
        case 'top':
            return 'bottom';
        case 'left':
            return 'right';
        case 'right':
            return 'left';
        default:
            return position;
    }
};

export const getTriangleOffset = (targetSize = 0, parentOffset = 0) => (
    (targetSize ? ((targetSize / 2) - 6) : 6) - (parentOffset || 0)
);

import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import { MainCaption } from '../../../pages/landing/mainStyles';
import BillingPeriodSwitcher from './BillingPeriodSwitcher';
import PricingTiers from './PricingTiers';

const Pricing = ({
  userLevel = 0,
  hasSubscription = false,
  canSelectPlans = false,
  location,
  history,
}) => {
  const [billingCycle, setBillingCycle] = useState('year');
  const [resubscribe, setResubscribe] = useState(false);


  useEffect(() => {
    setResubscribe(location.search.replace('?', '') === 'resubscribe');
  }, [location.search]);

  const handleSelectPro = useCallback(() => {
    history.push(`/payment?plan=pro&b=${billingCycle[0]}`);
  }, [billingCycle, history]);

  return (
    <div>
      {(userLevel < 2 || !hasSubscription) && (
        <MainCaption $center>
          Always FREE for personal use.<br />Simple plans for PRO users and TEAMS.
        </MainCaption>
      )}

      <BillingPeriodSwitcher
        cycle={billingCycle}
        switchTo={setBillingCycle}
      />

      <PricingTiers
        pricing={billingCycle}
        userLevel={userLevel}
        hasSubscription={hasSubscription}
        canSelectPlans={canSelectPlans}
        selectLabel={!userLevel ? 'select' : (resubscribe ? 'resubscribe' : 'upgrade')}
        onProSelect={handleSelectPro}
        showTrial={userLevel < 2 && !hasSubscription}
      />
    </div>
  );
};

export default withRouter(Pricing);

import React from 'react';
import { withRouter } from 'react-router';

import HTMLHeader from '../panels/mainPanel/HTMLHeader';
import Header from './landing/Header';
import BrowserExtensions from './landing/BrowserExtensions';
import IDEExtensions from './landing/IDEExtensions';
import Footer from './landing/Footer';
import MainLogin from './landing/MainLogin';

const PageExtensions = ({ auth = false }) => (
    <div>
        <HTMLHeader title="IDE & Browser extensions" />
        <Header />
        <IDEExtensions />
        <BrowserExtensions />
        { !auth && <MainLogin /> }
        <Footer />
    </div>
);

export default withRouter(PageExtensions);

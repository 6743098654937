import React from 'react';

import {
    PricingWrapper,
    PriceTiers,
} from './PricingStyles';


import PricingTierBasic from './PricingTierBasic';
import PricingTierPro from './PricingTierPro';
import PricingTierTeam from './PricingTierTeam';


export default ({
    pricing = 'year',
    userLevel = 0,
    hasSubscription = false,
    canSelectPlans = true,
    showTrial = true,
    selectLabel = 'select',
    onFreeSelect = () => {},
    onProSelect = () => {},
    onTeamSelect = () => {},
}) => (
    <PricingWrapper>
        <PriceTiers>
            <PricingTierBasic
                userLevel={userLevel}
                canSelectPlans={canSelectPlans}
                onFreeSelect={onFreeSelect}
            />

            <PricingTierPro
                pricing={pricing}
                userLevel={(hasSubscription && userLevel) || 0}
                canSelectPlans={canSelectPlans}
                selectLabel={selectLabel}
                onProSelect={onProSelect}
                showTrial={showTrial}
            />

            <PricingTierTeam
                pricing={pricing}
                canSelectPlans={canSelectPlans}
                selectLabel={selectLabel}
                onTeamSelect={onTeamSelect}
                showTrial={showTrial}
            />
        </PriceTiers>
    </PricingWrapper>
);

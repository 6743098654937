import { styled } from 'styletron-react';

import { getTheme } from '../../../ui/themes';


const colors = getTheme();

export const PricingWrapper = styled('div', {
    textAlign: 'center',
    margin: '100px auto',
});

export const PriceTiers = styled('div', {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
});

export const Tier = styled('div', props => ({
    flex: '1 1 0',
    borderRadius: '6px',
    background: colors.primaryText,
    border: `1px solid ${colors.primaryTextMuted}`,
    textAlign: 'center',
    minWidth: '200px',
    maxWidth: '320px',
    transform: 'translateZ(0)',

    ...(props.$pro && {
        border: `1px solid ${colors.primaryDark}`,
    }),

    ...(props.$team && {
        border: `1px solid ${colors.dangerDark}`,
    }),

    ':nth-child(2)': {
        marginLeft: '40px',
        marginRight: '40px',
    },
    ...(props.$level === 2 && { marginTop: '-20px' }),
    ...(props.$level === 3 && { marginTop: '-40px' }),

    ...(props.$overlay && {
        position: 'relative',
        opacity: '0.5',
    }),

    ...(props.$banner && {
        position: 'relative',
        overflow: 'hidden',

        '::before': {
            content: `"${props.$banner}"`,
            display: 'block',
            position: 'absolute',
            background: colors.success,
            top: '0',
            left: '-50%',
            width: '100%',
            padding: '12px',
            color: 'white',
            fontSize: '12px',
            fontWeight: '800',
            transform: 'rotateZ(-45deg) translate(5px, 40px)',
        },
    }),
}));

export const Title = styled('h1', props => ({
    ...(props.$level === 1 && { margin: '24px 0' }),
    ...(props.$level === 2 && { margin: '34px 0' }),
    ...(props.$level === 3 && { margin: '44px 0 26px' }),
    fontSize: '24px',
    textTransform: 'uppercase',
    color: colors.defaultTextLight,
    ...props.$pro && { color: colors.primaryDark },
    ...props.$team && { color: colors.dangerDark },
}));

export const Subtitle = styled('h2', {
    margin: '18px 0 24px',
    fontSize: '16px',
    fontWeight: '300',
    color: colors.defaultTextMuted,
});

export const TierFeatures = styled('ul', {
    margin: '0 0 40px',
    padding: '0',
    listStyle: 'none',
    textAlign: 'left',
});

export const Feature = styled('li', ({ $inactive }) => ({
    padding: '6px 6px 6px 44px',
    fontSize: '14px',
    lineHeight: '16px',
    ...($inactive && { color: colors.primaryTextMuted })
}));

export const FeatureIcon = styled('span', {
    position: 'absolute',
    marginLeft: '-24px',
    fill: '#88dd44',
});

export const Price = styled('div', props => ({
    color: colors.defaultTextLight,
    ...props.$pro && { color: colors.primaryDark },
    ...props.$team && { color: colors.dangerDark },
    fontSize: '48px',
    fontWeight: '800',
    lineHeight: '60px',
}));

export const PriceExtra = styled('div', {
    color: colors.defaultTextMuted,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '30px',
});

export const SmallPrint = styled('div', {
    fontSize: '12px',
    marginTop: '8px',
    ':last-child': { marginTop: '0' },
});

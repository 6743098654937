import React from 'react';
import { withRouter } from 'react-router';

import HTMLHeader from '../panels/mainPanel/HTMLHeader';
import Header from './landing/Header';
import Features from './landing/Features';
import Footer from './landing/Footer';
import MainLogin from './landing/MainLogin';

const PageFeatures = ({ auth = false }) => (
    <div>
        <HTMLHeader title="Features" />
        <Header />
        <Features plain />
        { !auth && <MainLogin /> }
        <Footer />
    </div>
);

export default withRouter(PageFeatures);

import { styled, withStyle } from 'styletron-react';
import { NavLink } from 'react-router-dom';

import styletronInstance from '../../../helpers/styletronInstance';
import { getTheme } from '../../../ui/themes';
import { SnipitLogo } from '../../../ui/icons';
import { PopoverArea } from '../../../ui/components/popover';
import UserPic from '../../../ui/components/userPic';


const colors = getTheme();

export const StyledGlobal = styled('div', {
    backgroundColor: colors.primaryDark,
    flex: '0 0 auto',
    width: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '12px',
});

export const SideGroup = styled('div', {
    flex: '0 0 auto',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
});

export const HomeLink = styled(NavLink, {
    display: 'block',
    width: '100%',
    padding: '10px 0',
    marginBottom: '12px',
    textAlign: 'center',
});

export const GlobalSnipitLogo = styled(SnipitLogo, {
    display: 'block',
    margin: '0 auto',
    fill: colors.primaryText,
});

export const SideButtonWrapper = styled('div', {
    flex: '0 0 auto',
    display: 'block',
    ':not(:first-child)': {
        marginTop: '6px',
    }
});


export const UserMenuArea = withStyle(PopoverArea, {
    position: 'relative',
    display: 'block',
    flex: '0 0 auto',
    marginTop: 'auto',
    marginBottom: '32px',
});

export const UserPhoto = styled(UserPic, {
    cursor: 'pointer'
});

export const BadgeWrapper = styled('div', {
    position: 'absolute',
    top: '0',
    right: '0',
});

const sideLinkStyles = {
    position: 'relative',
    display: 'block',
    padding: '6px 0',
    color: colors.primaryText,
    fill: colors.primaryText,
    fontSize: '9px',
    fontWeight: '100',
    textDecoration: 'none',
    textAlign: 'center',
    opacity: '0.75',
    transition: 'opacity .1s ease-out',

    ':hover': {
        opacity: '1',
    },
};

const sideLinkActiveStyles = {
    opacity: '1',
    '::before': {
        animation: 'GlobalNavLinkActive 0.3s ease-out',
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        width: '4px',
        borderRadius: '0 2px 2px 0',
        backgroundColor: colors.primary,
    },
};

// inject styles for sideLink early
styletronInstance.renderStyle(sideLinkStyles);
export const SideLink = styled(NavLink, sideLinkStyles);
export const activeSideLink = styletronInstance.renderStyle(sideLinkActiveStyles);


export const SideLogo = styled('div', {
    display: 'block',
    margin: '0 auto 4px',
});

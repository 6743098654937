// Globals
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import md5 from 'md5';

// Styled components
import UserProfile from './components/UserProfile';


// Main component
const UserProfileContainer = ({ userHash = () => {}, ...props }) => (
    !props.is_anon && props.userEmail && <UserProfile userHash={userHash()} {...props} />
);

export default withRouter(
    connect(
        ({ user }) => ({
            userEmail: user.email,
            userHash: () => user.email ? md5(user.email) : null,
            is_pro: user.level > 1,
            is_anon: !user.authenticated,
            has_subscription: user.has_subscription,
        }),
    )(UserProfileContainer)
);

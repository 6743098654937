import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Popover, { PopoverArea } from '../popover';
import { togglePopover } from '../popover/popoverStore';
import { Menu } from './ActionMenuStyles';


class ActionMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popoverId: 0,
        };

        this.menuArea = null;
    }

    render() {
        return (
            <PopoverArea $ref={el => this.menuArea = el}>
                <div onClick={() => this.props.toggleMenu(this.state.popoverId)}>
                    { this.props.toggler }

                    <Popover
                        area={this.menuArea}
                        id={this.state.popoverId}
                        isOpen={this.props.isOpen(this.state.popoverId)}
                        onClose={()=>{}}
                        onLoad={id => this.setState({ popoverId: id })}
                        position={this.props.position || 'bottom'}
                        align={this.props.align || 'start'}
                        offset={-8}
                        offsetFromTargetElement
                        targetElementSize={this.props.togglerSize || 32}
                    >
                        <Menu>
                            { this.props.children }
                        </Menu>
                    </Popover>
                </div>
            </PopoverArea>
        );
    }
}

export default withRouter(
    connect(
        ({ popover }) => ({
            isOpen: (id) => popover.find(item => item.id === id).isOpen,
        }),
        dispatch => ({
            toggleMenu: id => dispatch(togglePopover(id)),
        }),
    )(ActionMenu)
);

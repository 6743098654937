// Globals
import React from 'react';

// Styled components
import {
    SideGroup,
} from './SideStyles';
import SideButton from './SideButton';
import Badge from '../../../ui/components/badge/Pro';
import { List, Layers, EarthGlobe, Loupe, Crown } from '../../../ui/icons';


// Main component
const SideButtonsGroup = ({
    is_anon = true,
    is_pro = false,
    has_subscription = false,
}) => (
    <SideGroup>
        { !is_anon && <SideButton to="/lists" title="Lists" Icon={List} /> }
        { !is_anon && <SideButton to="/collections" title="Collections" Icon={Layers} /> }
        {/*<SideButton to="/tags" title="Tags" />*/}
        { !is_anon && <SideButton to="/search" title="Search" Icon={Loupe} /*pro_feature={!is_pro}*/ /> }
        <SideButton to="/public" title="Discover" Icon={EarthGlobe} />
        { !is_anon && (!is_pro || !has_subscription) && <SideButton to="/upgrade" title={<Badge $fill text="GO PRO" />} Icon={Crown} /> }
    </SideGroup>
);

export default SideButtonsGroup;

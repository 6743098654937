import React from 'react';

import {
    Footer,
    Wrapper,
    FlexWrapper,
    SectionBlock,
    FooterLink,
    LogoFooter,
    LogoSVG,
} from './mainStyles';


export default () => (
    <Footer>
        <Wrapper>
            <LogoFooter to="/">
                <LogoSVG size="24px" />
                Snipit
            </LogoFooter>
        </Wrapper>
        <FlexWrapper>
            <SectionBlock>
                <p>
                    Save and organize your code snippets in the cloud.
                    <br />Share and collaborate with your team members.
                ​</p>
            </SectionBlock>
            <SectionBlock $textRight>
                <p>
                    <FooterLink href="http://bitbucket.org/andreiduca/snip-it-client/issues?status=new&status=open" target="_blank">
                        Bugs and Suggestions
                    </FooterLink>
                    <span> | </span>
                    <FooterLink href="/terms.html" target="_blank">Terms of Service</FooterLink>
                    <span> | </span>
                    <FooterLink href="/privacy.html" target="_blank">Privacy Policy</FooterLink>
                    <br />
                    Follow us on:&nbsp;
                    <FooterLink href="https://fb.me/snipit.io" target="_blank">Facebook</FooterLink>
                    <span> | </span>
                    <FooterLink href="https://twitter.com/snipit_io" target="_blank">Twitter</FooterLink>
                </p>
            </SectionBlock>
        </FlexWrapper>
    </Footer>
);

import { styled } from 'styletron-react';
import { NavLink } from 'react-router-dom';

import { getTheme } from '../../themes';


export const Menu = styled('div', {
    padding: '4px 0',
    fontSize: '12px',
});

const menuItemStyles = {
    display: 'block',
    padding: '6px 20px',
    color: getTheme().defaultTextLight,
    whiteSpace: 'nowrap',
    textDecoration: 'none',

    ':hover': {
        background: getTheme().primaryTextMuted,
    },
};

export const MenuItem = styled('a', menuItemStyles);
export const MenuNavLink = styled(NavLink, menuItemStyles);
export const MenuSpacer = styled('div', {
    margin: '6px 20px',
    height: '0',
    borderBottom: `1px solid ${getTheme().primaryTextMuted}`,
});

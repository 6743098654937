export const freeFeatures = [
    { active: true, name: 'Unlimited public snippets' },
    { active: true, name: 'Add tags and stars' },
    { active: true, name: 'Bookmark and fork public snippets' },
    { active: true, name: 'Quick Search' },
    { active: false, name: 'Smart Search' },
    { active: true, name: 'Browser extensions' },
    { active: true, name: 'IDE extensions' },
    { active: true, name: 'Code embed' },
    { active: true, name: 'Markdown preview' },
    { active: false, name: 'Code preview' },
    { active: false, name: 'Themes' },
    { active: false, name: 'Private sharing' },
    { active: false, name: 'Teams and Collaboration' },
    { active: false, name: 'Role-based access control' },
    { active: false, name: 'Required 2FA for organizations' },
    { active: false, name: 'SAML single sign-on (SSO)' },
    { active: false, name: 'Centralized billing' },
    // { active: true, name: 'Log in with Google or GitHub' },
    // { active: true, name: 'Create standalone account', soon: true },
    // { active: false, name: 'SSO, 2FA' },
    { active: false, name: ' user badge', pro: true },
    // { active: true, name: 'Full SSL connection' },
    { active: true, name: 'Limited technical support' },
];

export const proFeatures = [
    { active: true, name: 'Unlimited public and private snippets' },
    { active: true, name: 'Add tags and stars' },
    { active: true, name: 'Bookmark and fork public snippets' },
    { active: true, name: 'Quick Search' },
    { active: true, name: 'Smart Search', soon: true },
    { active: true, name: 'Browser extensions' },
    { active: true, name: 'IDE extensions' },
    { active: true, name: 'Code embed' },
    { active: true, name: 'Markdown preview' },
    { active: true, name: 'Code preview', soon: true },
    { active: true, name: 'Themes', soon: true },
    { active: true, name: 'Private sharing with up to 5 users' },
    { active: false, name: 'Teams and Collaboration' },
    { active: false, name: 'Role-based access control' },
    { active: false, name: 'Required 2FA for organizations' },
    { active: false, name: 'SAML single sign-on (SSO)' },
    { active: false, name: 'Centralized billing' },
    // { active: true, name: 'Log in with Google or GitHub' },
    // { active: true, name: 'Create standalone account', soon: true },
    // { active: false, name: 'SSO, 2FA' },
    { active: true, name: ' user badge', pro: true },
    // { active: true, name: 'Full SSL connection' },
    { active: true, name: 'Full technical support' },
];

export const teamFeatures = [
    { active: true, name: 'Unlimited public and private snippets' },
    { active: true, name: 'Add tags and stars' },
    { active: true, name: 'Bookmark and fork public snippets' },
    { active: true, name: 'Quick Search' },
    { active: true, name: 'Smart Search & Team Search', soon: true },
    { active: true, name: 'Browser extensions' },
    { active: true, name: 'IDE extensions' },
    { active: true, name: 'Code embed' },
    { active: true, name: 'Markdown preview' },
    { active: true, name: 'Code preview', soon: true },
    { active: true, name: 'Themes', soon: true },
    { active: true, name: 'Unlimited private sharing' },
    { active: true, name: 'Teams and Collaboration' },
    { active: true, name: 'Role-based access control', soon: true },
    { active: true, name: 'Required 2FA for organizations', soon: true },
    { active: true, name: 'SAML single sign-on (SSO)', soon: true },
    { active: true, name: 'Centralized billing' },
    // { active: true, name: 'Log in with Google or GitHub' },
    // { active: true, name: 'Create standalone account', soon: true },
    // { active: true, name: 'SSO, 2FA', soon: true },
    { active: true, name: ' badge for all members', pro: true },
    // { active: true, name: 'Full SSL connection' },
    { active: true, name: 'Priority technical support' },
];

// Globals
import React from 'react';

// Styled components
import {
    UserMenuArea,
    UserPhoto,
} from './SideStyles';
import ActionMenu from '../../../ui/components/actionMenu';
import {
    MenuItem,
    MenuNavLink,
    MenuSpacer,
} from '../../../ui/components/actionMenu/ActionMenuStyles';
import UserBadge from './UserBadge';


// Main component
const UserProfile = ({ userEmail, userHash, is_pro, has_subscription }) => (
    <UserMenuArea>
        <ActionMenu
            togglerSize={36}
            toggler={<UserPhoto size={36} hash={userHash} name={userEmail} border />}
            position="top"
            align="start"
        >
            { !is_pro && <MenuNavLink to="/pricing">Pricing</MenuNavLink> }
            <MenuNavLink to="/extensions">Extensions</MenuNavLink>
            <MenuSpacer />
            <MenuNavLink to="/user">Settings</MenuNavLink>
            <MenuItem href="/auth/logout">
                Sign out
                <div><small>{userEmail}</small></div>
            </MenuItem>
        </ActionMenu>
        { is_pro && <UserBadge isPro={has_subscription} /> }
    </UserMenuArea>
);

export default UserProfile;

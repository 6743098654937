import React from 'react';
import { styled, withStyle } from 'styletron-react';

import config from '../../config/environments';
import { getTheme } from '../../ui/themes';
import {
    FlexWrapper,
    SectionTitle,
} from './mainStyles';

import BadgePro from '../../ui/components/badge/Pro';


const FeaturesSection = styled('section', ({ $plain = false }) => ({
    position: 'relative',
    zIndex: '4',
    padding: '100px 20px 40px',
    ...(!$plain && {
        padding: '0 20px 40px',
        background: getTheme().primaryTextMuted
    }),
}));


const Title = withStyle(SectionTitle, {
    width:'100%',
    marginBottom: '60px',
});

const Feature = styled('article', ({ $order }) => ({
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: '60px',
    color: getTheme().defaultTextLight,

    '@media (max-width: 567px)': {
        ...($order && { order: $order }),
    },

    '@media (min-width: 568px)': {
        width: '50%',
        padding: '0 40px 0 0',
    },
}));

const FeatureTitle = styled('h1', props => ({
    margin: '0 0 8px',
    fontSize: '20px',
    fontWeight: '600',

    ...(props.$flex && {
        display: 'flex',
        alignItems: 'center',
    }),
}));

const FeatureImage = styled('img', {
    display: 'block',
    float: 'left',
    width: '52px',
    marginRight: '20px',

    '@media (min-width: 568px)': {
        marginLeft: '20px',
    },
});

const FeatureDescription = styled('div', {
    display: 'block',
    float: 'right',
    width: 'calc(100% - 72px)',

    '@media (min-width: 568px)': {
        width: 'calc(100% - 92px)',
    },
});

export default ({ plain = false }) => (
    <FeaturesSection id="features" $plain={plain} style={!plain ? { marginTop: '-100px' } : {}}>
        <FlexWrapper>
            <Title>All your code snippets, in one place.</Title>

            <Feature>
                <FeatureImage src={`${config.application.cdnUrl}/images/icons/coding.svg`} alt="coding cloud" />
                <FeatureTitle>Save code snippets in the cloud</FeatureTitle>
                <FeatureDescription>Create code snippets in the web app, or use the browser extension to snip code blocks directly from the pages you visit.</FeatureDescription>
            </Feature>

            <Feature $order={2}>
                <FeatureImage src={`${config.application.cdnUrl}/images/icons/startup.svg`} alt="rocket" />
                <FeatureTitle $flex>
                    <BadgePro $rSpaced />
                    Power up your account
                </FeatureTitle>
                <FeatureDescription>
                    Be in full control of your code snippets.
                    Create private lists and collections and keep things to yourself.</FeatureDescription>
            </Feature>

            <Feature>
                <FeatureImage src={`${config.application.cdnUrl}/images/icons/list.svg`} alt="unordered list" />
                <FeatureTitle>Organize your code</FeatureTitle>
                <FeatureDescription>Group similar code snippets in lists and collections and keep everything organized. Nice and tidy!</FeatureDescription>
            </Feature>

            <Feature $order={2}>
                <FeatureImage src={`${config.application.cdnUrl}/images/icons/team.svg`} alt="team" />
                <FeatureTitle $flex>
                    <BadgePro $rSpaced />
                    Share & Collaborate
                </FeatureTitle>
                <FeatureDescription>
                    Let your friends and colleagues see your private lists and collections.
                    Give them write access for a true collaboration experience.</FeatureDescription>
            </Feature>

            <Feature>
                <FeatureImage src={`${config.application.cdnUrl}/images/icons/price-tag.svg`} alt="tag" />
                <FeatureTitle>Add tags and stars</FeatureTitle>
                <FeatureDescription>Add custom tags and mark your code snippets as favorites, so you can easily find what you need.</FeatureDescription>
            </Feature>

            <Feature $order={2}>
                <FeatureImage src={`${config.application.cdnUrl}/images/icons/loupe.svg`} alt="search loupe" />
                <FeatureTitle $flex>
                    <BadgePro $rSpaced />
                    Smart Search
                </FeatureTitle>
                <FeatureDescription>Find everything, from anywhere, quickly!
                    Or use the Smart Search <small><em>(coming soon)</em></small> to
                    intelligently find things by collection, list, title, syntax or tags.</FeatureDescription>
            </Feature>
        </FlexWrapper>
    </FeaturesSection>
);

import React from 'react';
import { styled } from 'styletron-react';

import Badge from '../../../ui/components/badge/Pro';


const PositionedBadge = styled('div', {
    position: 'absolute',
    bottom: '0',
    right: '0',
    fontSize: '6px',
    pointerEvents: 'none',
});

export default ({ isPro = false }) => (
    <PositionedBadge>
        <Badge $fill $rInset $em {...!isPro && { text: 'TRIAL' }} />
    </PositionedBadge>
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addPopover, removePopover, openPopover, closePopover, closeAllPopovers } from './popoverStore';

import { StyledPopover, Triangle } from './PopoverStyles';
import { getTrianglePosition } from './helpers';
import { hash } from '../../../helpers/functions';


class Popover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleOrientation = this.handleOrientation.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.handleDocumentClick);
        window.addEventListener('scroll', this.handleOrientation);
        window.addEventListener('resize', this.handleOrientation);
        this.handleOrientation();

        const id = this.props.id || hash();

        this.props.register(id);
        this.props.onLoad(id);

        this.setState({ id });
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.handleOrientation();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleDocumentClick);
        window.removeEventListener('scroll', this.handleOrientation);
        window.removeEventListener('resize', this.handleOrientation);

        this.props.unregister(this.state.id);
    }

    handleOrientation() {
        // TODO: implement popover auto-orientation
    }

    handleDocumentClick(ev) {
        const area = this.props.area || this.area;

        if (!area || !this.props.isOpen) {
            return;
        }

        if (!area.contains(ev.target)) {
            this.props.close(this.state.id);
            this.props.onClose(ev);
        }
    }

    render() {
        return (this.props.isOpen &&
            <StyledPopover
                $ref={node => (this.area = node)}
                $stretch={this.props.stretchWidth}
                $offsetFromTarget={this.props.offsetFromTargetElement}
                $position={this.props.position}
                $align={this.props.align}
                $offset={this.props.offset}
                $dark={this.props.dark}
                $transparent={this.props.transparent}
            >
                { this.props.triangle && (
                    <Triangle
                        $position={getTrianglePosition(this.props.position)}
                        $align={this.props.align}
                        $offset={this.props.offset}
                        $targetSize={this.props.targetElementSize}
                        $dark={this.props.dark}
                    />
                )}
                {this.props.children}
            </StyledPopover>
        );
    }
}

Popover.defaultProps = {
    id: null,
    stretchWidth: false,
    offset: 0,
    offsetFromTargetElement: 0,
    targetElementSize: null,
    triangle: true,
    position: 'bottom',
    align: 'start',
    dark: false,
    transparent: false,
};


Popover.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isOpen: PropTypes.bool.isRequired,
    onLoad: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    stretchWidth: PropTypes.bool,
    offset: PropTypes.number,
    offsetFromTargetElement: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    targetElementSize: PropTypes.number,
    triangle: PropTypes.bool,
    position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    align: PropTypes.oneOf(['start', 'center', 'middle', 'end']),
    dark: PropTypes.bool,
    transparent: PropTypes.bool,
};

export default connect(
    null,
    dispatch => ({
        register: (id) => dispatch(addPopover(id)),
        unregister: (id) => dispatch(removePopover(id)),
        closeAll: () => dispatch(closeAllPopovers()),
        open: (id) => dispatch(openPopover(id)),
        close: (id) => dispatch(closePopover(id)),
    }),
)(Popover);

import React from 'react';

import { Badge } from './Styles';
import { getTheme } from '../../themes';

export default ({
    children = null,
    text = null,
    style = null,
    ...props
}) => (
    <Badge
        $color={getTheme().primaryTextMuted}
        $textColor={getTheme().defaultText}
        $extraStyles={style}
        {...props}
    >
        { children || text }
    </Badge>
);

import { styled } from 'styletron-react';

// Styled components
const StyledApp = styled('div', {
    boxSizing: 'border-box',
    margin: '0',
    padding: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    alignContent: 'stretch',
    // background: `linear-gradient(to bottom left, #fff 0%, #f0f4f8 75%)`,
});


export default StyledApp;

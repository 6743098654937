const prices = {
  pro: {
    month: {
      price: 7,
      cycle: 'monthly',
      cycle_count: 1,
    },
    year: {
      price: 5,
      cycle: 'yearly',
      cycle_count: 12,
    },
  },
  team: {
    month: {
      price: 12,
      cycle: 'monthly',
      cycle_count: 1,
    },
    year: {
      price: 9,
      cycle: 'yearly',
      cycle_count: 12,
    },
  },
};

export const getPricing = plan => prices[plan];

export const getUnitPrice = (plan, cycle) => plan[cycle].price;

const fullCycleCost = cyclePlan => cyclePlan.price * cyclePlan.cycle_count;

export const getFrequency = (plan, cycle, yearlyCost = true) => (
  cycle === 'month'
    ? 'no annual commitment'
    : `billed${yearlyCost ? ` as €${fullCycleCost(plan[cycle])}` : ''} ${plan[cycle].cycle}`
);

import React from 'react';
import { styled, withStyle } from 'styletron-react';

import config from '../../config/environments';
import {
    FlexWrapper,
    Section,
    SectionTitle,
    SectionBlock,
    SectionRow,
    RowLabel,
    DesktopOnly,
    MobileOnly,
} from './mainStyles';

import { LinkButtonA } from '../../ui/components/input/ButtonStyles';


const LinkHref = withStyle(LinkButtonA, {
    display: 'inline-block',
    textAlign: 'center',
});

const Image = styled('img', {
    display: 'block',
    marginBottom: '12px',

    '@media (max-width: 767px)': {
        width: '48px',
    },
});

const SideImage = styled('img', {
    maxWidth: '100%',
    filter: 'drop-shadow(0 0 10px rgba(0,0,0,0.5))',

    '@media (max-width: 767px)': {
        marginTop: '20px',
    },
});

export default () => (
    <Section id="ide-extension" $padded $divider>
        <FlexWrapper>
            <SectionBlock $secondPosition $center>
                <SideImage src="https://media.giphy.com/media/1rSRfVh8PkGOayiwFm/source.gif" alt="animation of the Snipit.io ide extension" />
            </SectionBlock>
            <SectionBlock>
                <DesktopOnly>
                    <SectionTitle>Integrates with your IDE</SectionTitle>
                </DesktopOnly>
                <MobileOnly>
                    <SectionTitle>Integrates with your IDE</SectionTitle>
                </MobileOnly>
                <div>
                    Search code snippets stored in your Snipit library and paste them directly in your project.
                    No more context switching. Your code is available right in your editor.
                </div>
                <p>&nbsp;</p>
                <SectionRow>
                    <RowLabel>Available for:</RowLabel>
                    <LinkHref target="_blank" href="https://marketplace.visualstudio.com/items?itemName=snipit.vscode-extension">
                        <Image src={`${config.application.cdnUrl}/images/ide-vscode.png`} width="64" alt="vscode" />
                        VSCode
                    </LinkHref>
                </SectionRow>
            </SectionBlock>
        </FlexWrapper>
    </Section>
);

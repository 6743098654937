import React from 'react';
import { Button } from '../../../ui/components/input/ButtonStyles';

export default ({ cycle = 'year', switchTo = () => {} }) => (
    <div style={{ textAlign: 'center' }}>
        <p>Choose a billing cycle:</p>
        <Button
            $notRounded
            $muted
            $inverted={(cycle !== 'year')}
            onClick={() => switchTo('year')}
        >
            Yearly
        </Button>
        <Button
            $notRounded
            $muted
            $inverted={(cycle !== 'month')}
            onClick={() => switchTo('month')}
        >
            Monthly
        </Button>
    </div>
);

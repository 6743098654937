import { styled } from 'styletron-react';


export const Badge = styled('span', props => ({
    display: 'inline-block',
    padding: '0 0.5em',
    borderRadius: '2px',
    fontSize: props.$em ? 'inherit' : '8px',
    lineHeight: '1.5em',
    fontWeight: '400',
    textTransform: 'uppercase',
    verticalAlign: 'text-bottom',
    border: `1px solid ${props.$color}`,
    background: props.$color,
    color: props.$textColor,

    ...(!props.$fill && {
        background: 'none',
        color: props.$color,
    }),

    ...(props.$sub && {
        verticalAlign: 'text-bottom',
    }),
    ...(props.$sup && {
        verticalAlign: 'top',
    }),

    ...(props.$lSpaced && {
        marginLeft: '8px',
    }),
    ...(props.$rSpaced && {
        marginRight: '8px',
    }),
    ...(props.$lInset && {
        marginLeft: '-8px',
    }),
    ...(props.$rInset && {
        marginRight: '-8px',
    }),

    ...props.$extraStyles,
}));


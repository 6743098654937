import React from 'react';

import {
    Feature,
    FeatureIcon,
} from './PricingStyles';
import { BadgePro, BadgeMuted } from '../../../ui/components/badge';

import { Tick } from '../../../ui/icons'

export default ({ feature }) => (
    <Feature $inactive={!feature.active}>
        { feature.active && <FeatureIcon><Tick size="12px" /></FeatureIcon>}
        { feature.pro && feature.active && <BadgePro /> }
        { feature.pro && !feature.active && <BadgeMuted>PRO</BadgeMuted> }
        { feature.name }
        { feature.soon && <BadgeMuted $lSpaced>soon</BadgeMuted> }
    </Feature>
);

import React from 'react';

import {
    Section,
    Wrapper,
    MainCaption,
    MainCaptionBig,
    MobileOnly,
    DesktopOnly,
} from './mainStyles';

import Pricing from '../../panels/pricing/components/Pricing';


export default () => (
    <Section id="pricing">
        <Wrapper>
            <MobileOnly>
                <MainCaption $center>
                    Always FREE for personal use.<br/>Simple plans for PRO users and TEAMS.
                </MainCaption>
                <MainCaption $center>
                    PRO plans starting at
                    <MainCaptionBig>5€ / mo</MainCaptionBig>
                    <small style={{ marginTop: '-20px' }}>1 month free trial</small>
                </MainCaption>
            </MobileOnly>

            <DesktopOnly>
                <Pricing />
            </DesktopOnly>
        </Wrapper>
    </Section>
);

import { styled } from 'styletron-react';

import { getPosition, getAlignment, getTriangleOffset } from './helpers';
import { zIndex } from '../../../helpers/styles';


const Styles = {
    popoverArea: {
        position: 'relative',
    },
    popover: {
        position: 'absolute',
        zIndex: zIndex.front,
        background: 'white',
        borderRadius: '3px',
        filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3))',
        // boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2)',
        transition: 'all .2s ease-in-out',
    },
    popoverDark: {
        color: '#fff',
        background: '#222',
    },
    popoverTransparent: {
        background: 'none',
    },
    popoverStretch: {
        width: '100%',
    },

    popover_pos_top: (offset = 0) => ({
        bottom: `calc(100% + ${offset === true ? '10px' : `${parseInt(offset, 10)}px`})`,
    }),
    popover_pos_right: (offset = 0) => ({
        left: `calc(100% + ${offset === true ? '10px' : `${parseInt(offset, 10)}px`})`,
    }),
    popover_pos_bottom: (offset = 0) => ({
        marginTop: offset === true ? '10px' : `${parseInt(offset, 10)}px`,
    }),
    popover_pos_left: (offset = 0) => ({
        right: `calc(100% + ${offset === true ? '10px' : `${parseInt(offset, 10)}px`})`,
    }),

    popover_align_left: offset => ({
        left: `${parseInt(offset, 10) || 0}px`,
    }),
    popover_align_center: offset => ({
        left: offset ? `calc(50% + ${offset}px)` : '50%',
        transform: 'translateX(-50%)',
    }),
    popover_align_right: offset => ({
        right: `${parseInt(offset, 10) || 0}px`,
    }),
    popover_align_top: offset => ({
        top: `${parseInt(offset, 10) || 0}px`,
    }),
    popover_align_middle: offset => ({
        top: offset ? `calc(50% + ${offset}px)` : '50%',
        transform: 'translateY(-50%)',
    }),
    popover_align_bottom: offset => ({
        bottom: `${parseInt(offset, 10) || 0}px`,
    }),


    triangle: {
        content: '""',
        position: 'absolute',
        borderWidth: '6px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        pointerEvents: 'none',
    },

    triangle_pos_top: dark => ({
        borderColor: `transparent transparent ${dark ? '#222' : 'white'}`,
        top: '-12px',
    }),
    triangle_pos_bottom: dark => ({
        borderColor: `${dark ? '#222' : 'white'} transparent transparent`,
        bottom: '-12px',
    }),
    triangle_pos_left: dark => ({
        borderColor: `transparent ${dark ? '#222' : 'white'} transparent transparent`,
        left: '-12px',
    }),
    triangle_pos_right: dark => ({
        borderColor: `transparent transparent transparent ${dark ? '#222' : 'white'}`,
        right: '-12px',
    }),

    triangle_align_left: (targetSize, offset) => ({
        left: `${getTriangleOffset(targetSize, offset)}px`,
    }),
    triangle_align_center: () => ({
        left: '50%',
        marginLeft: '-6px',
    }),
    triangle_align_right: (targetSize, offset) => ({
        right: `${getTriangleOffset(targetSize, offset)}px`,
    }),
    triangle_align_top: (targetSize, offset) => ({
        top: `${getTriangleOffset(targetSize, offset)}px`,
    }),
    triangle_align_middle: () => ({
        top: '50%',
        marginTop: '-6px',
    }),
    triangle_align_bottom: (targetSize, offset) => ({
        bottom: `${getTriangleOffset(targetSize, offset)}px`,
    }),
};

export const StyledPopoverArea = styled('div', Styles.popoverArea);

export const StyledPopover = styled('div', props => ({
    ...Styles.popover,
    ...(props.$transparent && Styles.popoverTransparent),
    ...(props.$dark && Styles.popoverDark),
    ...(props.$stretch && Styles.popoverStretch),
    ...Styles[`popover_pos_${getPosition(props.$position)}`](props.$offsetFromTarget),
    ...Styles[`popover_align_${getAlignment(props.$position, props.$align)}`](props.$offset),
}));

export const Triangle = styled('div', props => ({
    ...Styles.triangle,
    ...Styles[`triangle_pos_${getPosition(props.$position)}`](props.$dark),
    ...Styles[`triangle_align_${getAlignment(props.$position, props.$align)}`](props.$targetSize, props.$offset),
}));

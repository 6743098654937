import React from 'react';

import {
    Main,
    MainAuth,
    MainAuthCaption,
} from './mainStyles';
import LoginLinks from './LoginLinks';

export default () => (
    <Main>
        <MainAuth>
            <MainAuthCaption>Start FREE. Sign in with:</MainAuthCaption>
            <LoginLinks />
        </MainAuth>
    </Main>
);

// Globals
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';

// Styled components
import {
    StyledGlobal,
    HomeLink,
    GlobalSnipitLogo,
} from './components/SideStyles';
import UserProfileContainer from './UserProfileContainer';
import SideButtonsGroup from './components/SideButtonsGroup';


// Main component
const SidePanelContainer = ({
    is_anon = true,
    is_pro = false,
    has_subscription=false,
}) => (
    <StyledGlobal>
        <HomeLink to={"/"}><GlobalSnipitLogo size="30px" /></HomeLink>

        <Route path="/(lists|collections|tags|public|search|upgrade|user)" render={() => (
            <SideButtonsGroup
                is_anon={is_anon}
                is_pro={is_pro}
                has_subscription={has_subscription}
            />
        )} />

        <UserProfileContainer />
    </StyledGlobal>
);

export default withRouter(
    connect(
        ({ user }) => ({
            is_anon: !user.authenticated,
            is_pro: user.level > 1,
            has_subscription: user.has_subscription,
        }),
    )(SidePanelContainer)
);

import React from 'react';

import { Badge } from './Styles';
import { getTheme } from '../../themes';

export default ({
    children = null,
    text = 'PRO',
    style = null,
    ...props
}) => (
    <Badge
        $color={getTheme().accent}
        $textColor={getTheme().primaryText}
        $extraStyles={style}
        {...props}
    >
        { children || text }
    </Badge>
);

import React from 'react';

import {
    teamFeatures,
} from '../features';

import {
    Tier,
    Title,
    Subtitle,
    TierFeatures,
    Price,
    PriceExtra,
    SmallPrint,
} from './PricingStyles';
import PricingFeature from './PricingFeature';

import { Button } from '../../../ui/components/input/ButtonStyles';
import { getFrequency, getPricing, getUnitPrice } from '../../../helpers/pricing';


const teamPricing = getPricing('team');

const PricingTierTeam = ({
    pricing = 'year',
    canSelectPlans = true,
    selectLabel = 'select',
    onTeamSelect = () => {},
    showTrial = true,
}) => (
    <Tier $level={3} $team $overlay $banner="COMING SOON">
        <Title $level={3}>Team</Title>
        <Subtitle>Share and collaborate with<br/>your team members.</Subtitle>
        <Price>€{getUnitPrice(teamPricing, pricing)}</Price>
        <PriceExtra>
            per month, for each user
            <SmallPrint>{getFrequency(teamPricing, pricing, false)}</SmallPrint>
            <SmallPrint>{ showTrial ? '1 month free trial' : <>&nbsp;</> }</SmallPrint>
        </PriceExtra>

        { canSelectPlans && (
            <PriceExtra>
                <Button $large disabled onClick={onTeamSelect}>
                    { selectLabel === 'upgrade' ? 'Upgrade' : 'Select plan' }
                </Button>
            </PriceExtra>
        )}

        <TierFeatures>
            { teamFeatures.map((f, i) => <PricingFeature key={i} feature={f} />) }
        </TierFeatures>
    </Tier>
);

export default PricingTierTeam;
